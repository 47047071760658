import React from "react";
import "./DemoSection.css";
import Button from "../../Button";

export default function DemoSection() {
    return (
        <section id="demo">
            <h1 className="demo-title">Demo</h1>
            <div className="demo-video-container">
                <iframe width="600" height="400" src="https://www.youtube.com/embed/iKB-3NEFpcE"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className={"demo-video"}
                />
            </div>
            <div className="demo-contact">
                <p>
                    Are you an organization interested in Holoriff?
                </p>
                <Button>
                    <a href = "mailto: andres@holoriff.com">Contact Us</a>
                </Button>
            </div>
        </section>
    );
}