import React, { useState } from "react";
import "./WaitlistForm.css"
import Button from "./Button";

export default function WaitlistForm() {
    const [values, setValues] = useState({
        email: '',
    });
    const [submitted, setSubmitted] = useState(false);
    const [valid, setValid] = useState(false);
    const [animationToggle, setAnimationToggle] = useState(false);


    const handleEmailInputChange = (event) => {
        event.persist();
        setValues((values) => ({
            ...values,
            email: event.target.value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setAnimationToggle(!animationToggle);
        // Standard email regex which works 99.9% of time
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(values.email) ) {
            // Call server to send email
            setValid(true);
            setSubmitted(true);
        } else {
            setValid(false);
            setSubmitted(true);
        }
    }

    return (
        <div className="form-container">
            <form className='form-subscribe' onSubmit={handleSubmit}>
                <input
                    id="email"
                    className="form-input"
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleEmailInputChange}
                />
                <Button className="form-button" type="submit">
                    Join
                </Button>
            </form>
            {submitted && !valid && animationToggle &&
                <span className={(animationToggle && !valid) ? "temporary-animation-1" : ""}>
                    Please enter a valid email address
                </span>}
            {valid && submitted && animationToggle &&
                <div className={(animationToggle && valid) ? "temporary-animation-1" : ""}>
                    Success! Thank you for registering
                </div>}
            {submitted && !valid && !animationToggle &&
            <span className={(!animationToggle && !valid) ? "temporary-animation-1" : ""}>
                    Please enter a valid email address
                </span>}
            {valid && submitted && !animationToggle &&
            <div className={(!animationToggle && valid) ? "temporary-animation-1" : ""}>
                Success! Thank you for registering
            </div>}
        </div>
    );
}