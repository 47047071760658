export default {
    fpsLimit: 120,
    detectRetina: true,
    particles: {
        color: {
            value: "#ffffff"
        },
        lineLinked: {
            enable: false
        },
        opacity: {
            value: 1
        },
        shape: {
            type: "circle"
        },
        size: {
            random: {
                enable: true,
                minimumValue: 0.5
            },
            value: 2
        },
        move: {
            size: true,
            enable: true,
            speed: 0.05,
            direction: "left",
            random: false,
            straight: false,
            outModes: {
                default: "out"
            },
            attract: {
                enable: false,
                rotate: { x: 600, y: 1200 }
            },
            trail: {
                enable: true,
                length: 10
            },
            warp: true
        }
    },
    absorbers: {
        orbits: true,
        destroy: false,
        opacity: 1,
        color: "#FFF",
        size: {
            value: 0,
            random: false,
            density: 500
        },
        position: {
            x: 50,
            y: 50
        }
    },
    background: {
        color: "#000"
    }
}