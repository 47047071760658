import Particles from "react-tsparticles";
import particlesConfig from "../particles-config";
import React from "react";

export default function ParticleBackground({ children }) {
    return (
        <div style={{ position: 'relative', background: "black"}}>
            <Particles
                params={particlesConfig}
                style={{
                    position: 'absolute',
                    zIndex: -1,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0
                }}
            />
            {children && <div style={{ position: 'relative' }}>{children}</div>}
        </div>
    );
}