import Button from "./Button";
import axios from "axios";

const DownloadHoloriffButton = ({}) => {
    async function download() {
        const method = 'GET';
        const url = '/download-file';
        axios
        .request({
            url,
            method,
            responseType: 'blob', //important
        })
        .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'Holoriff Installer.exe'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }

    return(
        <Button onClick={download}>Download Holoriff</Button>
    );
}

export default DownloadHoloriffButton;