import React, {useEffect, useState} from "react";
import "./NavBar.css";
import {faTimes, faBars} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthenticationButton from "./AuthenticationButton";

export default function NavBar({scrollPosition}) {
    const { height } = useWindowDimensions();

    const [active, setActive] = useState(false);
    const handleClick = () => {
        setActive(!active);
    };

    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    };

    const scrollToBottom = () =>{
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'auto'
        });
    };

    return (
        <>
            <nav className="navbar">
                <div className="navbar-logo">
                    <img src={"favicon.ico"} className={"navbar-icon"}/>
                    <h1>Holoriff</h1>
                </div>

                <div className={"menu-icon"} onClick={handleClick}>
                    <FontAwesomeIcon icon={active ? faTimes : faBars} />
                </div>

                <ul className={active ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item" onClick={scrollToTop}>
                        <NavLink scrollPosition={scrollPosition}
                                 leftBound={0}
                                 rightBound={height}
                                 title={"Home"}/>
                    </li>
                    <li className="nav-item" onClick={scrollToBottom}>
                        <NavLink scrollPosition={scrollPosition}
                                 leftBound={height}
                                 rightBound={height * 2}
                                 title={"Demo"}/>
                    </li>
                    <li className="nav-item nav-links-mobile">
                        <AuthenticationButton />
                    </li>
                    <div className="sign-in-button">
                        <AuthenticationButton />
                    </div>
                </ul>
            </nav>
        </>
    );
}

function NavLink({scrollPosition, leftBound, rightBound, link, title}) {
    let id = ""
    if (leftBound <= scrollPosition && scrollPosition <= rightBound) {
        id = "active-tab"
    }
    return(
        <>
            <a href={link} id={id} className={"nav-links"}> {title} </a>
        </>
    )
}
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

