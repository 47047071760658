import Typewriter from "../../Typewriter";
import React from "react";
import "./HomeSection.css"
import WaitlistForm from "../../WaitlistForm";
import AnimatedText from "../../AnimatedText";
import { useAuth0 } from "@auth0/auth0-react";
import DownloadHoloriffButton from "../../DownloadHoloriffButton";

export default function HomeSection() {
    const { isAuthenticated } = useAuth0();

    return (
        <section id="home">
            <div className="typewriter-effect">
                <h1>
                    <Typewriter
                        string='THE FUTURE OF HUMAN-COMPUTER INTERACTION'
                        delay={80}
                        cursorClassName='cursor'
                    />
                </h1>
            </div>

            <div className="introduction">
                {!isAuthenticated && <AnimatedText>
                    Holoriff
                </AnimatedText>}
                {!isAuthenticated && <p>
                    Control anything with just a wave of your hand.
                    Email list will open up soon!
                </p>}
                {!isAuthenticated && <WaitlistForm/>}
                {isAuthenticated && <DownloadHoloriffButton/>}
            </div>
        </section>
    );
};