import React, {Component, useEffect, useRef, useState} from 'react'
import './App.css';
import NavBar from "./components/NavBar";
import ParticleBackground from "./components/ParticleBackground";
import HomeSection from "./components/pages/homepage/HomeSection";
import DemoSection from "./components/pages/homepage/DemoSection";

export default function App() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className ="App">
            <NavBar className="navbar" scrollPosition={scrollPosition}/>
            <ParticleBackground />
            <HomeSection/>
            <DemoSection/>
        </div>
    );
}



