import React, {useEffect, useState} from "react";

export default function Typewriter({
   string,
   delay = 100,
   className,
   onComplete = () => {},
}) {
    const [text, setText] = useState('')
    const [pointer, setPointer] = useState(-1)

    useEffect(() => {
        if (pointer < string.length) {
            setTimeout(() => {
                setText(text + string.charAt(pointer))
                setPointer(pointer + 1)
            }, delay)
        } else {
            onComplete()
        }
    }, [pointer])

    return (
        <span className={className}>
      {text}
    </span>
    )
}